import Vue from 'vue';
import App from './layout/App'
import store from './store'
import router from '@/router'
import vSelect from 'vue-select'
import Loading from 'vue-loading-overlay';
import VueSimpleAlert from "vue-simple-alert";
import Vuelidate from 'vuelidate'
import VueMoment from 'vue-moment'
import moment from 'moment-timezone'

import VueGoodTablePlugin from 'vue-good-table';
import 'vue-good-table/dist/vue-good-table.css'

Vue.use(Vuelidate)
Vue.use(VueGoodTablePlugin);
Vue.use(VueSimpleAlert);
// Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css';
import "vue-sliding-pagination/dist/style/vue-sliding-pagination.css";
Vue.use(Loading,{
    // props
    color: '#000000',
    backgroundColor:'#000000',
    opacity: 0.5,
    isFullPage: true,
    loader: 'spinner',
    canCancel: false,
    width: 128,
    height: 128,
    zIndex: 10000,

  });

Vue.use(VueMoment, {
    moment,
})

Vue.component('v-select', vSelect)

import 'vue-select/dist/vue-select.css';
import LoadScript from 'vue-plugin-load-script';
Vue.use(LoadScript);

import './permission' // permission control


require('./bootstrap');

import VueMask from 'v-mask'
Vue.use(VueMask);
Vue.config.productionTip=false;

new Vue({
    el: '#app',
    router,
    store,
    render: h => h(App),
})