import { clientRoutes, photographRoutes , constantRoutes, verifyRoutes, pendingRoutes,
  superAdminRoutes } from '@/router'

const _ = require('lodash');

/**
* Use meta.role to determine if the current user has permission
* @param roles
* @param route
*/
function hasPermission(roles, route) {
  if (route.meta && route.meta.roles) {
    return roles.some(role => route.meta.roles.includes(role))
  } else {
    return true
  }
}

/**
* Filter asynchronous routing tables by recursion
* @param routes asyncRoutes
* @param roles
*/
export function filterAsyncRoutes(routes, roles) {
  const res = []

  routes.forEach(route => {
    const tmp = { ...route }
    if (hasPermission(roles, tmp)) {
      if (tmp.children) {
        tmp.children = filterAsyncRoutes(tmp.children, roles)
      }
      res.push(tmp)
    }
  })
  return res
}

function mergeRoutes(routesA, routesB){
for(let routeB of routesB){
let merged = false;
for(let routeA of routesA){
  if(routeA.path===routeB.path){
    if(routeB.important==true){
      routeA = routeB;
    }else{
      routeA.children = routeA.children.concat(routeB.children);
    }
    merged = true;
    break;
  }
}
if(!merged){
  routesA = routesA.concat(routeB);
}
}
return routesA;
}

const state = {
routes: [],
addRoutes: []
}

const mutations = {
SET_ROUTES: (state, routes) => {
state.addRoutes = routes
state.routes = constantRoutes.concat(routes)
}
}

const actions = {
generateRoutes({ commit }, user) {
return new Promise(resolve => {
  let accessedRoutes
  let roles = '';
  if(user.company!==null){
    roles = user.company.role;
  }
  //const roles = user.roles;
  // checking first if we verify emails :
  if(user.email_verified_at===null){
    accessedRoutes = filterAsyncRoutes(verifyRoutes)
  }
  else if (roles == 'super-admin'){
    console.log('setting superadmin routes');
    accessedRoutes = filterAsyncRoutes(superAdminRoutes)
  }
  else if (roles == 'client') 
  {
    accessedRoutes = filterAsyncRoutes(clientRoutes)
  }
  else if (roles == 'photograph') 
  {
    console.log('loading photograph routes');
    accessedRoutes = filterAsyncRoutes(photographRoutes)
  } 
  else 
  {
    accessedRoutes = filterAsyncRoutes(pendingRoutes)
  }
  
  commit('SET_ROUTES', accessedRoutes)
  resolve(accessedRoutes)
})
}
}

export default {
namespaced: true,
state,
mutations,
actions
}
