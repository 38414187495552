const getters = {
    token: state => state.user.token,
    name: state => state.user.name,
    roles: state => state.user.roles,
    routes: state => state.permission.routes,
    user: state => state.user.user,
    permission_routes: state => state.permission.routes,
    company: state=>state.user.user.company,
    open_projects: state=>state.user.open_projects,
    quote_projects: state=>state.user.quote_projects,
    closed_projects:state=>state.user.closed_projects,
    errorLogs: state => state.errorLog.logs
}
export default getters