import { login, logout, getInfo, verify, changeActiveCompany } from '@/api/user'
import { getToken, setToken, removeToken } from '@/utils/auth'
import router, { resetRouter } from '@/router'
import { reject } from 'lodash'

const state = {
  token: getToken(),
  name: '',
  avatar: '',
  introduction: '',
  roles: '',
  layout: 'client',
  user: {},
  company:{},
  open_projects:[],
  quote_projects:[],
  closed_projects:[],
}

const mutations = {
  SET_USER: (state, user) => {
    state.user = user
  },
  SET_TOKEN: (state, token) => {
    state.token = token
  },
  SET_INTRODUCTION: (state, introduction) => {
    state.introduction = introduction
  },
  SET_NAME: (state, name) => {
    state.name = name
  },
  SET_AVATAR: (state, avatar) => {
    state.avatar = avatar
  },
  SET_ROLES: (state, roles) => {
    state.roles = roles
  },
  SET_LAYOUT: (state, layout) => {
    state.layout = layout
  },
  SET_COMPANY: (state, company) => {
    state.company = company
    state.open_projects = company.open_projects;
    state.quote_projects = company.quote_projects;
    state.closed_projects = company.closed_projects;
  }

}

const actions = {
  // user login
  login({ commit }, userInfo) {
    const { username, password } = userInfo
    return new Promise((resolve, reject) => {
      login({ username: username.trim(), password: password }).then(data => {
        commit('SET_TOKEN', data.access_token)
        setToken(data.access_token)
        resolve()
      }).catch(error => {
        reject("Sorry, there was an error with your identification, check your email and password and if the problem persist contact with your administrator.")
      })
    })
  },

  getProjects({ commit, state}) {
    return new Promise((resolve, reject) => {

    })
  },
  // get user info
  getInfo({ commit, state }) {
    return new Promise((resolve, reject) => {
      getInfo(state.token).then(response => {
        
        const data = response
        if (!data.company) {
          reject('Verification failed, please Login again.')
        }
        const { name } = data
        const roles = data.company.role;
        let layout = roles;
       

        commit('SET_USER', data)
        commit('SET_LAYOUT', layout)
        commit('SET_ROLES', roles)
        commit('SET_NAME', name);
        commit('SET_COMPANY', data.company)
        resolve(data)
      }).catch(error => {
        reject(error)
      })
    })
  },
  verifyEmail( { commit, state, dispatch},params){
    return new Promise( (resolve, reject) => {
      console.log(state);
      verify(params).then( async () => {
        const user = await dispatch('getInfo')
        resetRouter()
        // generate accessible routes map based on roles
        const accessRoutes = await dispatch('permission/generateRoutes', user, { root: true })

        // dynamically add accessible routes
        router.addRoutes(accessRoutes)
        resolve();
      }).catch(error => {
        reject(error)
      })
    })
  },
  
  changeActiveCompany({ commit, dispatch}, company_id) {
    return new Promise((resolve, reject) => {
      changeActiveCompany(company_id).then( async response => {
        const data = response
        if(!data.company){
          reject('Change failed, please try again.')
        }
        const { name } = data
        const roles = data.company.role;
        let layout = roles;

        commit('SET_USER',data);
        commit('SET_LAYOUT', layout)
        commit('SET_ROLES', roles)
        commit('SET_NAME', name);
        commit('SET_COMPANY', data.company)
        // generate accessible routes map based on roles
        const user = await dispatch('getInfo')
        resetRouter()
        const accessRoutes = await dispatch('permission/generateRoutes', user, { root: true })
        // dynamically add accessible routes
        router.addRoutes(accessRoutes)

        resolve(data)
      }).catch(error => {
        reject(error)
      })
    })
  },
  // user logout
  logout({ commit, state, dispatch }) {
    return new Promise((resolve, reject) => {
      logout(state.token).then(() => {
        commit('SET_TOKEN', '')
        commit('SET_ROLES', '')
        commit('SET_USER',{})
        removeToken()
        resetRouter()

        // reset visited views and cached views
        // to fixed https://github.com/PanJiaChen/vue-element-admin/issues/2485
        dispatch('tagsView/delAllViews', null, { root: true })

        resolve()
      }).catch(error => {
        reject(error)
      })
    })
  },

  // remove token
  resetToken({ commit }) {
    return new Promise(resolve => {
      commit('SET_TOKEN', '')
      commit('SET_ROLES', [])
      removeToken()
      resolve()
    })
  },

  
  // dynamically modify permissions
  changeRoles({ commit, dispatch }, role) {
    return new Promise(async resolve => {
      const token = role + '-token'

      commit('SET_TOKEN', token)
      setToken(token)

      const { roles } = await dispatch('getInfo')

      resetRouter()

      // generate accessible routes map based on roles
      const accessRoutes = await dispatch('permission/generateRoutes', roles, { root: true })

      // dynamically add accessible routes
      router.addRoutes(accessRoutes)

      // reset visited views and cached views
      dispatch('tagsView/delAllViews', null, { root: true })

      resolve()
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
