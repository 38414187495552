<template>
    <div id="app">
        <header>
            <div class="logo">
                <router-link :to="home_link"><img src="/img/logo-small-white.svg" alt="In-Actua" /></router-link>
            </div>
            <div class="user_menu active" v-if="$store.getters.roles!=''">
                <ul>
                    <li>
                        <router-link :to="home_link">
                            <div>
                                <img src="/img/icons/user.svg" alt="User" />
                            </div>
                            {{ $store.getters.name }}
                        </router-link>
                    </li>
                    <!--
                    <li v-if="$store.getters.roles!='pending'">
                        <router-link :to="home_link+'settings'">
                            <div>
                                <img src="/img/icons/cogs.svg" alt="Settings" />
                            </div>
                            Settings
                        </router-link>
                    </li>
                    -->
                    <li>
                        <a href="#none" @click="logout">
                            <div>
                               <img src="/img/icons/log-out.svg" alt="Logout" />
                            </div>
                            Logout
                         </a>
                    </li>
                </ul>
            </div>
            <template v-if="$store.getters.roles!=='' && $store.getters.roles!='pending'">
                <div class="user_menu2" v-if="$store.getters.roles=='photograph' ">
                    <ul>
                        <li><router-link to="/photograph/new">New Product</router-link></li>
                        <li><router-link to="/photograph/currents">Currents Records</router-link></li>
                        <li><router-link to="/photograph/refused">Refused Records</router-link></li>
                    </ul>
                </div>
                <div class="user_menu2" v-if="$store.getters.roles=='client' ">
                    <ul>
                        <li><router-link to="/client/new">New Project</router-link></li>
                        <li><router-link to="/client">In Progress</router-link></li>
                        <li><router-link to="/client/quotes">Quotes</router-link></li>
                        <li><router-link to="/client/invoices">Invoices</router-link></li>
                        <li><router-link to="/client/licences">Licences Agreeement</router-link></li>
                        <li><router-link to="/client/download">Download</router-link></li>
                    </ul>
                </div>
                <div class="user_menu2" v-if="$store.getters.roles=='super-admin' ">
                    <ul>
                        <li><router-link to="/super-admin/users">Users</router-link></li>
                        <li><router-link to="/super-admin/photos">Photos</router-link></li>
                        <li><router-link to="/super-admin/quotes">Quotes</router-link></li>
                        <li><router-link to="/super-admin/invoices">Invoices</router-link></li>
                        <li><router-link to="/super-admin/payments">Pending Payments</router-link></li>
                        <li><router-link to="/super-admin/licences">Licences Agreements</router-link></li>
                        <li><router-link to="/super-admin/proofs">Proofs</router-link></li>
                    </ul>
                </div>
            </template>
            <div class="main_menu">
                <img src="/img/logo-large-white.svg" alt="In-Actua" />
                <button class="hamburger" @click="menu_open=!menu_open">
                    <div class="hamburger-box">
                        <div class="hamburger-inner"></div>
                    </div>
                </button>
                <menu v-bind:class="{'is-active':menu_open}">
                    <!--
                    <li><router-link to="/photographes">Photographers .</router-link></li>
                    <li><router-link to="/series">Series .</router-link></li>
                    <li><router-link to="/journal">Journal .</router-link></li>
                    -->
                    <li><router-link to="/contact">Contact .</router-link></li>
                    <li><router-link :to="home_link">My Account .</router-link></li>
                    <li v-if="$store.getters.user.companies && $store.getters.user.companies.length>1" class="company-selector-menu" @click="show_company_selector=true;">{{ $store.getters.user.company.name }} </li>
                </menu>
            </div>
        </header>
        <router-view></router-view>
        <footer>
            <div class="copyright-links">
                <div class="copyright">
                    2021 - In-Actua / Clermes
                </div>
                <menu>
                    <li><router-link to="/contact">Contact us</router-link></li>
                   <!-- <li><router-link to="/legal">Legal Notices</router-link></li>-->
                </menu>
            </div>
            <div class="social">
                <a href="https://www.instagram.com" target="_blank">
                    <img src="/img/instagram.svg" alt="Instagram" />
                </a>
                <a href="https://www.linkedin.com" target="_blank">
                    <img src="/img/linkedin.svg" alt="LinkedIn" />
                </a>
            </div>
        </footer>
        <div v-if="$store.getters.user.companies && $store.getters.user.companies.length>1" class="company_selector" v-bind:class="{show : show_company_selector}">
            <div class="center-content">
                <div class="close-box" @click="show_company_selector=false;">X</div>
                <h2>Change your active company</h2>
                <label>
                    <span>Company : </span>
                    <select v-model="current_company">
                        <option v-for="company in $store.getters.user.companies" :key="company.id" :value="company.id">{{company.name}}</option>
                    </select>
                </label><br />
                <br />
                <a href="#none" class="button active" @click="handleChangeCompany">
                    Change &gt;&gt;&gt;
                </a>
            </div>
        </div>
        <div v-if="is_mobile" class="mobile_alert show">
            <div class="center-content">
                <h2>Please use another device</h2>
                <p>
                    In-actua.com is a website for media professionals.<br />
                    It is not designed to be viewed on a smartphone.<br />
                    We invite you to log in to your account from a computer or a pad.
                </p>
                <p>
                    An emergency! just call us <a href="tel:+33189168353">+33 1 89 16 83 53</a> or send us an email to <a href="mailto:contact@in-actua.com">contact@in-actua.com</a>
                </p>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'App',
    data(){
        return {
            show_company_selector: false,
            menu_open: false,
            current_company:0,
            is_mobile:false,
        }
    },
    watch:{
        show_company_selector: function (val){
            if(val){
                console.log('toggle company_select');
                this.current_company=this.$store.getters.user.company.id;
            }
        }
    },
    computed:{
        home_link: function(){
            if(this.$store.getters.roles!='pending' && this.$store.getters.roles!=''){
                return '/'+this.$store.getters.roles+'/';
            }
            else{
                return '/';
            }
        }
    },
    methods:{
        handleChangeCompany(){
            this.$store.dispatch('user/changeActiveCompany',this.current_company)
            .then(() => {
                this.show_company_selector=false;
                this.$router.push({path: '/'+this.$store.getters.roles+'/'});
            })
            .catch((e)=>{
                this.$alert("There was a problem to change your active company",'Error','error');
            })

        },
        logout(){
            this.$store.dispatch('user/logout')
            .then(() => {
                this.$router.push({path: '/'});
            })
            .catch((e) => {
                this.$alert("There was a problem to log you out",'Error','error');
            })
        },
    },
    mounted(){
        if( screen.width <= 1024 ) {
            this.is_mobile=true;
        }
        else {
            this.is_mobile = false;
        }
    }
}
</script>
<style lang="scss" scoped>
.company-selector-menu{

    background-color: #444;
    line-height: 30px;
    text-indent: 10px;
    cursor: pointer;
}

.company_selector, .mobile_alert{
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: -1;
    background-color: rgba(0,0,0,.5);
    opacity: 0;
    transition: all 0.5s;
    &.show{
        z-index: 1050;
        opacity: 1;
    }
    .center-content{
        position: absolute;
        top: 100px;
        width: 500px;
        min-height: 150px;
        margin-left: calc(50vw - 250px);
        background-color: #FFF;
        padding: 20px;
        .close-box{
            cursor: pointer;
            background-color: #000;
            color: #FFF;
            font-size: 30px;
            padding: 5px;
            text-align: center;
            position: absolute;
            top: 0;
            right: 0;
        }
        a{
      width: calc(50% - 25px);
      padding-left: 55px;
      margin-top: 16px;
      display: block;
      font-weight: normal;
      border-top: 3px solid #666;
      line-height: 35px;
      font-size: 12px;
      text-decoration: none;
      color: #666;
      transition: all .3s;
      &:hover{
        color: #000;
        border-top-color: #000;
      }
      &.active{
        text-transform: uppercase;
        border-top-color: #000;
        color: #000;
        &:hover{
          color: #666;
          border-top-color: #666;
        }
      }
    }

    }
}
.mobile_alert{
    .center-content{
        width: 96vw;
        max-width: 96vw;
        margin-left: 2vw;
        a{
            width: auto;
        }
    }
}
</style>
