import request from '@/utils/request'

export function login(data) {
  return request({
    url: '/api/login',
    method: 'post',
    data
  })
}

export function getInfo(token) {
  return request({
    url: '/api/user',
    method: 'get',
    params: { token }
  })
}

export function changeActiveCompany(id){
  return request({
    url: '/api/user/active_company',
    method: 'post',
    data:{
      company_id:id
    }
  })
}

export function verify(params) {
  return request({
    url: '/api/verify',
    method: 'get',
    params: params
  })
}
export function lost(params) {
  return request({
    url: '/api/lost',
    method: 'post',
    data:params
  })
}
export function reset(params) {
  return request({
    url: '/api/reset',
    method: 'post',
    data:params
  })
}

export function reverify() {
  return request({
    url: '/api/reverify',
    method: 'post'
  })
}

export function logout() {
  return request({
    url: '/api/logout',
    method: 'post'
  })
}


/**
 * STANDAR REST METHODS
 */

 export function fetchUser(id) {
  return request({
    url: '/api/users/' + id,
    method: 'get'
  })
}
export function fetchCompany(id) {
  return request({
    url: '/api/company/' + id,
    method: 'get'
  })
}
export function setCompanyRole(id,role) {
  return request({
    url: '/api/company/'+id+'/role',
    method: 'post',
    data: {
      role: role
    }
  })
}
export function setDelayedPayment(id,status){
    return request({
        url: '/api/company/'+id+'/delayed_payment',
        method: 'post',
        data:{
            status: status
        }
    })
}
export function fetchCompanyList(query) {
  return request({
    url: '/api/company',
    method: 'get',
    params: query
  })
}

export function fetchList(query) {
  return request({
    url: '/api/users',
    method: 'get',
    params: query
  })
}

export function updateUser(id, params) {
  return request({
    url: '/api/users/' + id,
    method: 'patch',
    params: params
  })
}
export function updateCompany(id, params) {
  return request({
    url: '/api/company/' + id,
    method: 'patch',
    params: params
  })
}

export function createUser(data) {
  return request({
    url: '/api/user',
    method: 'post',
    data: data
  })
}

export function deleteUser(id) {
  return request({
    url: '/api/users/' + id,
    method: 'delete'
  })
}
