import router from './router'
import store from './store'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import { getToken } from '@/utils/auth'
import Swal from "sweetalert2";


NProgress.configure({ showSpinner: false }) // NProgress Configuration

const whiteList = ['/','/login', '/lost', '/reset', '/auth-redirect', '/signup', '/contact', '/legal','/photographes','/series','/journal']

router.beforeEach(async(to, from, next) => {
    NProgress.start()

    const hasToken = getToken()
    
    if(hasToken){
        
        if (to.path === '/login') {
            // if is logged in, redirect to the home page
            next({ path: '/' })
            NProgress.done()
        } else {
            // determine whether the user has obtained his permission roles through getInfo
            const hasRoles = store.getters.roles && store.getters.roles!=''
            if (hasRoles) {
                if(to.redirectedFrom=='/' && store.getters.user.email_verified_at===null){
                    next({path:'/verify',relace:true});
                }
                else if(to.redirectedFrom=='/' && store.getters.user.company && store.getters.user.company.role == 'pending'){
                    next({path:'/pending',replace:true});
                }
                else{
                    console.log('going next : '+to.path);
                    next();
                }
            } else {
                try {
                    // get user info
                    // note: roles must be a object array! such as: ['admin'] or ,['developer','editor']
                    const user = await store.dispatch('user/getInfo')

                    // generate accessible routes map based on roles
                    const accessRoutes = await store.dispatch('permission/generateRoutes', user)

                    // dynamically add accessible routes
                    router.addRoutes(accessRoutes)

                    // if user not verified redirect to  verify page :
                    if(user.email_verified_at===null){
                        if(to.redirectedFrom){
                            next({ path:to.redirectedFrom, replace: true })
                        }
                        else{
                            next({path:'/verify',replace:true});
                        }
                    }
                    else if(user.company && user.company.role == 'pending'){
                        next({path:'/pending',replace:true});
                    }
                    else{
                        // hack method to ensure that addRoutes is complete
                        // set the replace: true, so the navigation will not leave a history record
                        // redirecting to /role
                        var tto = to.path;
                        if(to.redirectedFrom){
                            tto=to.redirectedFrom;
                        }
                        var res = tto.split('/');
                        
                        if(res.length>1 && res[1]==user.company.role){
                            next({ path:tto, replace: true })
                        }
                        else{
                            next({ path: '/'+user.company.role , replace: true })
                        }
                    }
                } catch (error) {
                    // remove token and go to login page to re-login
                    await store.dispatch('user/resetToken')
                    Swal.fire({
                        title: 'Error!',
                        text: error || 'Has Error',
                        
                        confirmButtonText: 'OK'
                      });
                    
                    next(`/login?redirect=${to.path}`)
                    NProgress.done()
                }
            }
        }
    }
    else{
        /* has no token*/
        if (whiteList.indexOf(to.path) !== -1) {
            // in the free login whitelist, go directly
            next()
        }
        else {
            // other pages that do not have permission to access are redirected to the login page.
            next(`/login?redirect=${to.path}`)
            NProgress.done()
        }
    }
});
router.afterEach(() => {
    // finish progress bar
    NProgress.done()
})
  