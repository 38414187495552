import { trimEnd } from 'lodash'
import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

/**
 * constantRoutes
 * a base page that does not have permission requirements
 * all roles can be accessed
 */
export const constantRoutes = [
    {
        path: '/redirect/:path*',
        hidden: true,
        component: () => import('@/views/redirect/index')
    },
    {
        path: '/',
        hidden: true,
        component: () => import('@/views/account/login')
    },
    {
        path: '/login',
        hidden: true,
        component: () => import('@/views/account/login')
    },
    {
        path: '/lost',
        hidden: true,
        component: () => import('@/views/account/lost')
    },
    {
        path: '/reset',
        hidden: true,
        component: () => import('@/views/account/reset')
    },
    {
        path: '/signup',
        hidden: true,
        component: () => import('@/views/account/signup')
    },
    {
        path: '/auth-redirect',
        hidden: true,
        component: () => import('@/views/account/auth-redirect')
    },
    {
        path: '/contact',
        hidden: true,
        component: () => import('@/views/general/contact')
    },
    {
        path: '/404',
        hidden: true,
        component: () => import('@/views/error-page/404')
    },
    {
        path: '/401',
        hidden: true,
        component: () => import('@/views/error-page/401')
    },
    { path: '*', redirect: '/404', hidden: true }
]

export const verifyRoutes=[
    {
        path: '/verify',
        hidden: true,
        component: () => import('@/views/account/verify')
    },
    {
        path: '/404',
        hidden: true,
        component: () => import('@/views/error-page/404')
    },
    { path: '*', redirect: '/404', hidden: true}
]
export const pendingRoutes=[
    {
        path: '/pending',
        hidden: true,
        component: () => import('@/views/account/pending')
    },
    {
        path: '/404',
        hidden: true,
        component: () => import('@/views/error-page/404')
    },
    { path: '*', redirect: '/404', hidden: true}
]
export const clientRoutes=[
    {
        path: '/client/',
        hidden: true,
        component: () => import('@/views/client/progress')
    },
    {
        path: '/client/new',
        hidden: true,
        component: () => import('@/views/client/new')
    },
    {
        path: '/client/quotes',
        hidden: true,
        component: () => import('@/views/client/quotes')
    },
    {
        path: '/client/invoices',
        hidden: true,
        component: () => import('@/views/client/invoices')
    },
    {
        path: '/client/licences',
        hidden: true,
        component: () => import('@/views/client/licences')
    },
    {
        path: '/client/download',
        hidden: true,
        component: () => import('@/views/client/downloads')
    },
    {
        path: '/client/project/:id',
        hidden: true,
        props: true,
        component: () => import('@/views/client/project')
    },
    {
        path: '/client/quote/:id',
        hidden: true,
        props:true,
        component: () => import('@/views/client/quote-make')
    },

    { path: '*', redirect: '/404', hidden: true }
]

export const photographRoutes=[
    {
        path: '/photograph/',
        hidden: true,
        component: () => import('@/views/photograph/home')
    },
    {
        path: '/photograph/new',
        hidden: false,
        component: () => import('@/views/photograph/new')
    },
    {
        path: '/photograph/currents',
        hidden: false,
        component: () => import('@/views/photograph/currents')
    },

    { path: '*', redirect: '/404', hidden: true }
]

export const superAdminRoutes=[
    {
        path: '/super-admin/',
        hidden: true,
        component: () => import('@/views/super-admin/home')
    },
    {
        path: '/super-admin/users',
        hidden: true,
        component: () => import('@/views/super-admin/users')
    },
    {
        path: '/super-admin/company/:id',
        hidden: true,
        component: () => import('@/views/super-admin/company-form'),
        props: true
    },
    {
        path: '/super-admin/photos',
        hidden: true,
        component: () => import('@/views/super-admin/photos'),
        props: true
    },
    {
        path: '/super-admin/photos/:batch_id',
        hidden: true,
        component: () => import('@/views/super-admin/validate-photos'),
        props: true
    },
    {
        path: '/super-admin/quotes',
        hidden: true,
        component: () => import('@/views/super-admin/quotes'),
        props: true
    },
    {
        path: '/super-admin/invoices',
        hidden: true,
        component: () => import('@/views/super-admin/invoices'),
        props: true
    },
    {
        path: '/super-admin/licences',
        hidden: true,
        component: () => import('@/views/super-admin/licences'),
        props: true
    },
    {
        path: '/super-admin/payments',
        hidden: true,
        component: () => import('@/views/super-admin/payments'),
        props: true
    },
    {
        path: '/super-admin/proofs',
        hidden: true,
        component: () => import('@/views/super-admin/proofs'),
        props: true
    },
    {
        path: '/super-admin/project/:id',
        hidden: true,
        component: () => import('@/views/super-admin/project'),
        props: true
    },
    { path: '*', redirect: '/404', hidden: true }
]

const createRouter = () => new Router({
    mode: 'history', // require service support
    scrollBehavior: () => ({ y: 0 }),
    routes: constantRoutes
  })

  const router = createRouter()

  // Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
  export function resetRouter() {
    const newRouter = createRouter()
    router.matcher = newRouter.matcher // reset router
  }

  export default router
